.loading_loader__5nMo_ {
  width: 6rem;
  height: 6rem;
  background: var(--foreground);
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  animation: loading_animloader__jEm_i 1s ease-out infinite;
}

@keyframes loading_animloader__jEm_i {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.me_me__nwAix,
.me_text__dicTl {
  display: flex;
}

.me_me__nwAix {
  align-items: center;
  gap: 1rem;
}

.me_text__dicTl {
  flex-direction: column;
  gap: 0.2rem;
}

.me_pfp__pSodY {
  margin: 0 0.25rem;
}

.name_name__TTQVc {
  cursor: pointer;
}

.name_selectBlocked__nZaWx {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.name_flag__DhUkK,
.name_flag__DhUkK > svg {
  height: 0.8em;
}

.name_flag__DhUkK {
  display: inline-flex;
  margin: 0 0.5rem;
}

.page_main__nw1Wk {
  --padding-x: 5rem;
  --padding-y: 2rem;

  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  min-height: 100vh;
  padding: var(--padding-y) 0;
}

.page_column__hsMp1 {
  display: flex;
  flex-direction: column;
  width: var(--column-width);
  max-width: var(--column-width);
}

.page_column__hsMp1.page_me__0aB4u {
  display: none;
}

.page_column__hsMp1.page_content___38fW {
  gap: 2rem;
}

.page_column__hsMp1.page_canvas__t5Zrn {
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  cursor: -webkit-grab;

  cursor: grab;

  height: 100%;
  max-height: calc(100vh - 2 * var(--padding-y));
}

.page_column__hsMp1.page_canvas__t5Zrn,
.page_canvasWrapper__Ow4qq {
  width: 30vw;
  max-width: var(--column-width);
}

.page_canvasWrapper__Ow4qq {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30vw;
  max-height: var(--column-width);
}

.page_element__PHdZ3 {
  padding: 0.1rem 0;
}

.page_element__PHdZ3.page_flex__io2QE > a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.page_element__PHdZ3.page_logo__ikIZE > a {
  text-decoration: none;
}

.page_element__PHdZ3.page_logo__ikIZE > a > .page_text__9vMJ3 {
  text-decoration: underline;
}

.page_section__zQZ2o.page_attributions__FVJA_ {
  font-size: 0.8rem;
}

.page_section__zQZ2o > h2 {
  padding-bottom: 1rem;
}

@media (max-width: 950px) {
  .page_main__nw1Wk {
    flex-direction: column;
    gap: 1rem;
  }

  .page_column__hsMp1.page_me__0aB4u {
    display: flex;
  }

  .page_column__hsMp1.page_content___38fW {
    order: 1;
  }

  .page_column__hsMp1.page_canvas__t5Zrn,
  .page_canvasWrapper__Ow4qq {
    width: 100%;
    aspect-ratio: 3 / 2 !important;

    /* fix threejs making canvas bigger than the screen */
    max-width: calc(100vw - 2 * var(--padding-x));
  }

  .page_canvasWrapper__Ow4qq {
    height: auto;
  }

  .page_column__hsMp1.page_content___38fW > .page_me__0aB4u {
    display: none;
  }
}

@media (max-width: 700px) {
  .page_main__nw1Wk {
    --padding-x: 2rem;
    --padding-y: 2rem;
    padding: var(--padding-y) var(--padding-x);
  }

  .page_column__hsMp1 {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .page_canvasWrapper__Ow4qq {
    aspect-ratio: 1 / 1 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .page_canvasWrapper__Ow4qq {
    background: radial-gradient(#fff3 0%, #fff0 65%);
  }
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_5b2c46 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}

